import React, { Fragment, TouchEvent, useCallback, useRef } from 'react';

import { appInject } from '@core/di/utils';
import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import Table from '@mui/material/Table';
import { Loading } from '@shared/components/loading';
import { StatusLabel } from '@shared/components/new-design/status-label';
import { StatusLabelType } from '@shared/components/new-design/status-label/status-label-type';
import { AppTableBody, AppTableCell, AppTableHead, AppTableRow } from '@shared/components/table';
import { DI_TOKENS } from '@shared/constants/di';
import { OrderDetailsType } from '@shared/enums/order-details-type';
import { SmartCoinOrderListModel } from '@shared/models/orders/smart-coin-order-list-model';
import { amountPipe, minDigitsAfterDot } from '@shared/pipes';
import { ISmartCoinDetailsDialogViewModel } from '@shared/types/smart-coin-details-dialog-view-model';

import { styles } from './orders-table.styles';
import { INavigationVM } from '@shared/types/navigation-vm';
import { reaction } from 'mobx';

export interface OrdersTableProps extends AppWithStyles<typeof styles> {
  isLoading: boolean;
  isLoadingPage: boolean;
  orders: SmartCoinOrderListModel[];
  currency: string;
  onEndScroll?: () => void;
}

const OrdersTableComponent: React.FC<OrdersTableProps> = ({
  classes: c,
  orders,
  isLoading,
  isLoadingPage,
  currency,
  onEndScroll,
}) => {
  const tableRef = useRef<HTMLTableElement | null>(null);
  const smartCoinDetailsDialogVM = React.useMemo(
    () => appInject<ISmartCoinDetailsDialogViewModel>(DI_TOKENS.smartCoinDetailsDialogViewModel),
    [],
  );

  const navigationVM = appInject<INavigationVM>(DI_TOKENS.navigationViewModel);

  reaction(
    () => navigationVM.recallActivity,
    () => scrollToTop(),
  );

  const scrollToTop = useCallback(() => {
    tableRef.current?.scrollIntoView();
  }, []);

  const handleViewScroll = (e: TouchEvent<HTMLDivElement>) => {
    if (e.currentTarget.scrollHeight === e.currentTarget.scrollTop + e.currentTarget.offsetHeight) {
      onEndScroll && onEndScroll();
    }
  };

  const header = (
    <AppTableHead>
      <AppTableRow>
        <AppTableCell>{currency}</AppTableCell>
        <AppTableCell>{t`Costs`}</AppTableCell>
        <AppTableCell>{t`Type`}</AppTableCell>
        <AppTableCell>{t`Status`}</AppTableCell>
      </AppTableRow>
    </AppTableHead>
  );

  const content = (
    <div className={c.root} onScroll={handleViewScroll}>
      <Table className={c.table} ref={tableRef}>
        {header}
        <AppTableBody>
          {orders.map((order) => {
            const { id, fiatCurrency, quantity, totalAmount, status, type } = order.asJson;

            const purchaseComponent = (
              <div className={`${c.status} ${c.purchase}`}>{t`Purchase`}</div>
            );
            const sellComponent = <div className={`${c.status} ${c.sell}`}>{t`Sell`}</div>;

            return (
              <Fragment key={id}>
                <AppTableRow
                  onClick={() =>
                    smartCoinDetailsDialogVM.openDialog(
                      id,
                      OrderDetailsType.PURCHASE_OR_SELL_SMART_COIN,
                    )
                  }
                >
                  <AppTableCell className={c.mainRow}>
                    {minDigitsAfterDot(amountPipe(quantity, 8))}
                  </AppTableCell>
                  <AppTableCell className={c.mainRow}>{`${minDigitsAfterDot(
                    amountPipe(totalAmount, 2),
                  )} ${fiatCurrency}`}</AppTableCell>
                  <AppTableCell className={c.mainRow}>
                    {type === 'Buy' ? purchaseComponent : sellComponent}
                  </AppTableCell>
                  <AppTableCell className={c.mainRow}>
                    <StatusLabel status={status} type={StatusLabelType.ORDER} />
                  </AppTableCell>
                </AppTableRow>
              </Fragment>
            );
          })}
        </AppTableBody>
      </Table>
      {isLoadingPage ? (
        <div className={c.loadingPage}>
          <Loading size={70} />
        </div>
      ) : (
        <></>
      )}
    </div>
  );

  const noContent = (
    <div className={c.root}>
      <Table className={c.table}>{header}</Table>
      <div className={c.noData}>{t`It looks like You still have no orders`}</div>
    </div>
  );

  if (isLoading)
    return (
      <div className={c.loading}>
        <Loading size={70} />
      </div>
    );

  return orders.length ? content : noContent;
};

export const OrdersTable = appWithStyles(styles)(OrdersTableComponent);
